<template>
  <v-container id="auth" style="height: max-content!important;" class="fill-height justify-center">
    <v-stepper v-model="step" class="pa-5" style="width: 800px">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="step > 1" step="1"> Digite seu e-mail </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Código de Verificação
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3"> Atualizar </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 4" step="4"> Completo </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-slide-y-transition appear>
            <p class="text-center" style="font-size: 1.2rem">
              Insira o e-mail cadastrado na sua conta!
              <br />
              Vamos te enviar um código para recuperação da sua senha.
            </p>
          </v-slide-y-transition>

          <v-slide-y-transition appear>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                color="normalred"
                label="E-mail..."
                @keydown.prevent.enter="sendEmail"
                prepend-icon="mdi-email"
                required
              />
            </v-form>
          </v-slide-y-transition>

          <div class="text-right mt-5">
            <v-btn
              color="normalred"
              rounded
              @click="sendEmail"
              :loading="loading"
              :disabled="!valid"
            >
              Enviar e-mail
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-slide-y-transition appear>
            <p class="text-center" style="font-size: 1.2rem">
              Insira o código de recuperação que enviamos para o seu E-mail.
            </p>
          </v-slide-y-transition>

          <v-slide-y-transition appear>
            <v-form ref="secondForm" lazy-validation>
              <v-text-field
                @keydown.space.prevent
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="4"
                color="#197DFF"
                :rules="[(v) => !!v || 'Pin requerido']"
                label="Código"
                v-model="pin"
                @keydown.prevent.enter="validatePin"
                prepend-icon="mdi-account"
              />
            </v-form>
          </v-slide-y-transition>

          <div class="text-right mt-5">
            <v-btn color="normalred" rounded @click="validatePin" :loading="loading">
              Validar
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form ref="thirdForm">
            <v-text-field
              v-model="newPassword"
              :rules="[(v) => v.length > 5 || 'A senha deve ter no mínimo 5 caracteres']"
              type="password"
              @keydown.prevent.enter="update"
              label="Nova Senha"
              required
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              :rules="[(v) => v == newPassword || 'As senhas não batem']"
              label="Confirme a Senha"
              type="password"
              @keydown.prevent.enter="update"
              required
            ></v-text-field>
          </v-form>

          <div class="text-right mt-5">
            <v-btn color="normalred" rounded @click="update" :loading="loading">
              Atualizar
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <p class="text-center" style="font-size: 1.2rem">
            Sua senha foi atualizada com sucesso!
          </p>

          <div class="text-center mt-5">
            <v-btn color="normalred" rounded @click="toLogin" :loading="loading">
              Ir para Login
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import Vue from "vue";

export default {
  name: "ForgotPassword",

  components: {},

  data: () => ({
    step: 1,
    loading: false,
    error: false,
    valid: false,
    pin: "",
    newPassword: "",
    confirmPassword: "",
    userId: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail requerido",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    apiService: new ApiService(),
  }),

  methods: {
    sendEmail() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const user = {
          Email: this.email,
        };

        this.apiService
          .post("user/forgot-password", user)
          .then((response) => {
            this.userId = response.userId;
            this.loading = false;
            this.step++;
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "E-mail não cadastrado",
              message: err.body.message,
              trace: null,
            });

            this.loading = false;
          });
      }
    },

    validatePin() {
      if (this.$refs.secondForm.validate()) {
        this.loading = true;

        const data = { Code: parseInt(this.pin), UserId: this.userId };

        this.apiService
          .post("user/forgot-password/validate", data)
          .then(() => {
            this.loading = false;
            this.step++;
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não validado",
              message: err.body.message,
              trace: null,
            });

            this.loading = false;
          });
      }
    },

    update() {
      if (this.$refs.thirdForm.validate()) {
        this.loading = true;

        const data = {
          UserId: this.userId,
          NewPassword: this.newPassword,
          Code: parseInt(this.pin),
        };

        this.apiService
          .post("user/forgot-password/reset", data)
          .then(() => {
            this.loading = false;
            this.step++;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
#auth {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins,sans-serif!important;

  background: none;
  max-width: unset;
  height: 50vh;
}
.fill-height {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
